(angular => {
  "use strict";

  angular.module("Participant").controller("Participant.ImportModalCtrl", [
    "$timeout",
    "$mdDialog",
    "Toast",
    "Loader",
    "$filter",
    "$window",
    "EventService",
    "ParticipantService",
    "participants",
    "participantType",
    ImportModalCtrl
  ]);

  function ImportModalCtrl($timeout, $mdDialog, Toast, Loader, $filter, $window, eventService, participantService, participants, participantType) {
    let event;

    this.error = false;
    this.query = { page: 1, limit: 10 };
    this.participantType = participantType;
    this.participants = participants.map(p => {
      Object.keys(p).forEach(key => {
        if (typeof p[key] !== 'string') return;
        p[key] = (p[key] || '').toString().trim();
      });

      p.type = participantType;
      let cpf = (p.cpf || '').toString().replace(/\D/g, "");
      if(cpf.length === 11){
        p.cpf = cpf;
      } else {
        p.foreign_document = true;
      }

      if (typeof p.birth_date === 'string') {
        let [day, month, year] = p.birth_date.toString().split('/');
        year = year.length === 2 ? `19${year}` : year;

        p.birth_date = new Date(year, Number(month) - 1, day);
      }

      return p;
    });

    eventService.current().then(eventData => event = eventData);

    this.save = () => {
      if (moment() > moment(event.limit_date) && !event.online) {
        return $mdDialog.show(
          $mdDialog.alert()
          .title("A data para o cadastro de novos participantes expirou ")
          .ok("Ok")
        );
      }

      return Loader.add(participantService.importList(this.participants)).then((result) => {
        Toast.show("Participantes importados com sucesso", "success");

        if (result.errors.length === 0) {
          $mdDialog.hide();
          return;
        }

        this.error = true;
        this.query.page = 1;
        this.successCount = this.participants.length - result.errors.length;
        this.participants = result.errors.map(data => {
          data.participant.error = data.error;
          return data.participant;
        });

      }).catch(err => Toast.httpHandler(err));
    };

    this.cancel = $mdDialog.cancel;
    this.close = $mdDialog.hide;
  }
})(angular || {});
